import React from "react";
import AOS from "aos";
import Landing from "./pages/Landing";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  AOS.init();

  return (
    <div className="App">
      <Landing />
    </div>
  );
}

export default App;
