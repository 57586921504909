import React from "react";
import Confetti from "react-confetti";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as Vect2023 } from "../../assets/svg/2023.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-circle-down.svg";
import MetricsImg from "../../assets/svg/metrics.svg";
import FundRaiseImg from "../../assets/svg/fund-raise.svg";
import BirthdayImg from "../../assets/svg/birthday.svg";
import { ReactComponent as Gtb9Psb } from "../../assets/svg/product-updates/gtb-9psb.svg";
import { ReactComponent as Savings } from "../../assets/svg/product-updates/savings.svg";
import { ReactComponent as BillsPayment } from "../../assets/svg/product-updates/bills-payment.svg";
import { ReactComponent as Statements } from "../../assets/svg/product-updates/statements.svg";
import { ReactComponent as ApiKeys } from "../../assets/svg/product-updates/api-keys.svg";
import { ReactComponent as Dashboard } from "../../assets/svg/product-updates/dashboard.svg";
import { ReactComponent as Audits } from "../../assets/svg/product-updates/audits.svg";
import { ReactComponent as Webhook } from "../../assets/svg/product-updates/webhook-delivery.svg";
import AnchorSidebrief from "../../assets/svg/Anchor x Sidebrief.svg";
import AnchorYebox from "../../assets/svg/Anchor x Yebox.svg";
import AnchorVisa from "../../assets/svg/AnchorVisa.svg";
import Crew1 from "../../assets/png/sailors/1.png";
import Crew2 from "../../assets/png/sailors/2.png";
import Crew3 from "../../assets/png/sailors/3.png";
import Crew4 from "../../assets/png/sailors/4.png";
import Crew5 from "../../assets/png/sailors/5.png";
import Crew6 from "../../assets/png/sailors/6.png";
import Crew7 from "../../assets/png/sailors/7.png";
import Crew8 from "../../assets/png/sailors/8.png";
import Meet1 from "../../assets/png/meet-1.png";
import Meet2 from "../../assets/png/meet-2.png";
import Hackaton from "../../assets/png/hackaton-1.png";
import Hackaton2 from "../../assets/png/hackaton-2.png";
import Hackaton3 from "../../assets/png/hackaton-3.png";
import AfricaNXT from "../../assets/png/events/AfricaNXT.png";
import LagosTechFest from "../../assets/png/events/Anchor x Lagos Tech Fest.png";
import Neobanking from "../../assets/png/events/neobanking.png";
import AfricaStartupFestival from "../../assets/png/events/africa-startup-festival.png";
import Blackops from "../../assets/png/events/blackops.png";
import LagosStartupExpo from "../../assets/png/events/lagos-startup-expo.png";
import LagosTechfest from "../../assets/png/events/lagos-techfest.png";
import Pitch2win from "../../assets/png/events/pitch2win.png";
import StartupWorkshop from "../../assets/png/events/startup-workshop.png";
import DigitalTechWeek from "../../assets/png/events/digital-tech-week.png";
import { ReactComponent as Anchor3d } from "../../assets/svg/anchor-3d.svg";
import "./landing.scss";

const Landing = () => {
  const metrics = [
    {
      label: "Live Customers & Partners",
      value: "60+ ",
    },
    {
      label: "Total Transaction Volume",
      value: "₦400,000,000,000",
    },
    {
      label: "Amount Raised",
      value: "$2,400,000",
    },
    {
      label: "API Calls",
      value: "5 Million",
    },
  ];

  const productUpdates = [
    {
      image: <Gtb9Psb />,
      title: "GTB and 9PSB Virtual Account Numbers",
      description:
        "GTB and 9PSB Virtual Account Numbers - Our customers can now offer these virtual accounts to their customers",
    },
    {
      image: <Savings />,
      title: "Savings API",
      description:
        "Our savings API allows our customers to offer tailored and customisable savings products to their end customers.",
    },
    {
      image: <BillsPayment />,
      title: "Bills Payment API",
      description:
        "Offer airtime and data purchase, electricity, and cable payments to your customers",
    },
    {
      image: <Statements />,
      title: "Monthly Statements",
      description:
        "Account statements can now be generated at the beginning of every month",
    },
    {
      image: <ApiKeys />,
      title: "API Keys",
      description: "Create and manage permission based API Keys.",
    },
    {
      image: <Dashboard />,
      title: "Dashboard Refresh",
      description: "Our dashboard is wearing a new look for easy navigation",
    },
    {
      image: <Audits />,
      title: "Audit Trail",
      description: "Record all user and system-generated actions",
    },
    {
      image: <Webhook />,
      title: "Webhook Delivery History and Resend",
      description: "Creation and management of webhooks on the dashboard",
    },
  ];

  const sailors = [Crew1, Crew2, Crew3, Crew4, Crew5, Crew6, Crew7, Crew8];

  const eventsOthers = [
    {
      image: LagosTechfest,
      name: "Lagos Techfest",
    },
    {
      image: LagosStartupExpo,
      name: "Lagos Startup Expo",
    },
    {
      image: AfricaStartupFestival,
      name: "Africa Startup Festival",
    },
    {
      image: Pitch2win,
      name: "Pitch2win",
    },
    {
      image: Blackops,
      name: "Blackops",
    },
    {
      image: StartupWorkshop,
      name: "Startup Workshop",
    },
  ];

  return (
    <div className="year_in_review">
      <Confetti numberOfPieces={80} />

      <section className="banner">
        <div className="banner_inner desktop_max_width">
          <Logo className="logo" />

          <div
            className="banner_text"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="1000"
          >
            <h1>365 Days of Growth</h1>

            <p>
              We started Anchor to make it easy for businesses of all kinds to
              build and launch compliant financial products. We do this by
              providing the complete infrastructure, integration, partnership,
              compliance and regulatory support required for businesses to get
              to market quickly.
            </p>

            <p>
              What followed has been a year of listening to customers and
              building
            </p>
          </div>

          <div className="banner_img_container">
            <Vect2023
              className="banner_img"
              data-aos="flip-up"
              data-aos-duration="1000"
            />
          </div>

          <div
            className="scroll_down"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="1000"
          >
            <span>Let's dive in</span>
            <ArrowDown />
          </div>
        </div>
      </section>

      <section className="metrics">
        <div className="metrics_inner flex_items items_end desktop_max_width">
          <div className="flex_item_left">
            <div className="section_tag" data-aos="zoom-in-down">
              Our year in numbers
            </div>

            <div className="metrics_list">
              {metrics.map((metric) => (
                <div
                  className="metric"
                  key={metric.label}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="metric_label">{metric.label}</div>
                  <div className="metric_value">{metric.value}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex_item_right">
            <img src={MetricsImg} alt="metrics" />
          </div>
        </div>
      </section>

      <section className="funds_birthday gray_bg">
        <div className="flex_items desktop_max_width">
          <div className="flex_item_left">
            <img src={FundRaiseImg} alt="fund raise" />
          </div>

          <div className="flex_item_right">
            <div className="section_tag" data-aos="zoom-in-down">
              We raised funds 🎉
            </div>

            <p data-aos="fade-up" data-aos-duration="1000">
              We secured $2.4 million in a seed round led by Goat Capital, with
              participation from FoundersX, Rebel Fund, Pioneer Fund, Y
              Combinator, Byld Ventures, Future Africa, and many other visionary
              backers.
            </p>

            <div>
              <a
                href="https://www.benjamindada.com/anchor-raises-2-4-million-seed/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read More
              </a>
            </div>
          </div>
        </div>

        <div className="flex_items reverse desktop_max_width">
          <div className="flex_item_left">
            <img src={BirthdayImg} alt="" />
          </div>

          <div className="flex_item_right">
            <div className="section_tag" data-aos="zoom-in-down">
              Happy Birthday Anchor 🥳
            </div>

            <p data-aos="fade-up" data-aos-duration="1000">
              We turned one in August and celebrated with a team dinner that had
              some of our investors and partners in attendance. It's been an
              incredible journey, and we couldn't have done it without your
              support.
            </p>

            <div>
              <a
                href="https://springfivemedia36.pixieset.com/anchor1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Pictures
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="product_updates">
        <div className="product_updates_header">
          <div className="desktop_max_width">
            <div className="section_tag" data-aos="zoom-in-down">
              Product Updates 💎
            </div>

            <p data-aos="fade-up" data-aos-duration="1000">
              Listening to customer feedback, we introduced exciting updates
              that now enable our customers to build better products and expand
              the functionality of existing ones.
            </p>
          </div>
        </div>

        <div className="product_updates_list desktop_max_width">
          {productUpdates.map((productUpdate) => (
            <div className="product_update" key={productUpdate.title}>
              <div className="product_update_image" data-aos="flip-up">
                {productUpdate.image}
              </div>
              <div
                className="product_update_title"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {productUpdate.title}
              </div>
              <p data-aos="fade-up" data-aos-duration="1000">
                {productUpdate.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="partnerships desktop_max_width">
        <div className="section_tag" data-aos="zoom-in-down">
          Partnerships 🤝
        </div>

        <div className="flex_items">
          <div className="flex_item_left">
            <div className="img_container">
              <img src={AnchorSidebrief} alt="Anchor and Sidebrief" />
            </div>

            <h3 data-aos="fade-up" data-aos-duration="1000">
              Anchor with Sidebrief (Techstars ‘22)
            </h3>

            <p data-aos="fade-up" data-aos-duration="1000">
              We partnered with Sidebrief, a startup offering business
              registration and regulatory compliance across Africa, the US, and
              the UK. Under our partnerships, people who register their
              businesses using Sidebrief are eligible for discounted services
              from Anchor to build and scale their businesses.
            </p>
          </div>

          <div className="flex_item_right">
            <div className="img_container">
              <img src={AnchorYebox} alt="Anchor and Yebox" />
            </div>

            <h3 data-aos="fade-up" data-aos-duration="1000">
              Yebox
            </h3>

            <p data-aos="fade-up" data-aos-duration="1000">
              We've joined forces with Yebox, a company that specialises in
              creating custom software solutions for clients. Through this
              partnership, Yebox's clients enjoy discounted offerings from
              Anchor.
            </p>
          </div>
        </div>
      </section>

      <section className="visa gray_bg">
        <div className="flex_items justify_center desktop_max_width">
          <div className="flex_item_left">
            <img
              src={AnchorVisa}
              alt="Anchor and Visa"
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </div>

          <div className="flex_item_right">
            <div className="section_tag" data-aos="zoom-in-down">
              We got in 🚀
            </div>

            <p data-aos="fade-up" data-aos-duration="1000">
              Anchor was selected to participate in the Visa Accelerator Program
              joining 22 other African startups. The Visa Accelerator Program is
              designed for growth-stage companies looking to expand their market
              reach, with a focus on product solutions, technical integration,
              and fast-tracked commercialisation.  We are excited to leverage
              this experience to enhance our solutions further and provide even
              greater value to our customers.
            </p>
          </div>
        </div>
      </section>

      <section className="new_sailors">
        <div className="new_sailors_inner desktop_max_width">
          <div className="section_tag light" data-aos="zoom-in-down">
            New Sailors Aboard 👩🏽‍💼
          </div>

          <p className="light" data-aos="fade-up" data-aos-duration="1000">
            We welcomed some awesome superstars across various teams bringing in
            expertise and fresh perspectives to the Anchor voyage.
          </p>

          <div className="sailors_list">
            {sailors.map((sailor, index) => (
              <div className="sailor" key={index}>
                <img
                  src={sailor}
                  alt="Team Member"
                  data-aos="flip-up"
                  data-aos-duration="1000"
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="we_met_you">
        <div className="we_met_you_text desktop_max_width">
          <div className="section_tag" data-aos="zoom-in-down">
            We met you 🌺
          </div>

          <div className="flex_items">
            <div className="flex_item_left">
              <h3>Anchor Exclusive Open House</h3>

              <p data-aos="fade-up" data-aos-duration="1000">
                We organised the Anchor Open House to interact with industry
                stakeholders, investors, regulators, and partners. We shared our
                journey and various product offerings on a night full of fun and
                laughter.
              </p>
            </div>

            <div className="flex_item_right">
              <h3>Customer Meet and Greet</h3>

              <p data-aos="fade-up" data-aos-duration="1000">
                Every quarter, we host our customers to understand the products
                they are building and how our solutions can support them. The
                energy from these events leaves us buzzing and we can’t wait to
                host more events in 2024.
              </p>
            </div>
          </div>
        </div>

        <div className="meet_images flex_items desktop_max_width">
          <div className="flex_item_left">
            <img src={Meet2} alt="" />
          </div>

          <div className="flex_item_right">
            <img src={Meet1} alt="" />
          </div>
        </div>
      </section>

      <section className="hackaton gray_bg">
        <div className="hackaton_inner flex_items desktop_max_width">
          <div className="flex_item_left">
            <div className="section_tag" data-aos="zoom-in-down">
              Ready, Set, Build. 🧩
            </div>

            <p data-aos="fade-up" data-aos-duration="1000">
              In September, we hosted our first hackathon, bringing together
              17 participants with one task-  to build innovative fintech
              products using Anchor’s APIs.
            </p>

            <p data-aos="fade-up" data-aos-duration="1000">
              Team Brazen, the winners, went home with 1 million Naira. They
              built a recurring payment solution that allowed users to make
              multiple transfers at once.
            </p>

            <p data-aos="fade-up" data-aos-duration="1000">
              The runners-up, Team Bitbrawlers, won a cash prize of Five Hundred
              Thousand Naira. They built Stash — a business banking solution.
            </p>

            <a
              href="https://springfivemedia36.pixieset.com/anchorshackathonevent/"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Pictures
            </a>
          </div>

          <div className="flex_item_right">
            <img src={Hackaton} alt="hackaton" />
          </div>
        </div>

        <div className="hackaton_inner hackaton_b_images flex_items desktop_max_width">
          <div className="flex_item_left">
            <img src={Hackaton3} alt="hackaton" />
          </div>

          <div className="flex_item_right">
            <img src={Hackaton2} alt="hackaton" />
          </div>
        </div>
      </section>

      <section className="events_community">
        <div className="events_community_inner desktop_max_width">
          <div className="events_community_header">
            <div className="section_tag text_center" data-aos="zoom-in-down">
              Events & Community 🎗️
            </div>

            <p
              className="text_center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              We were OUTSIDE a lot this year! <br />
              From industry conferences and tech summits to community-driven
              gatherings. We were building new relationships and supporting the
              tech community.
            </p>
          </div>

          <div className="flex_items dark_bg br_8">
            <div className="flex_item_left">
              <img src={AfricaNXT} alt="AfricaNXT Lagos" />
            </div>

            <div className="flex_item_right e_right">
              <h3 className="white_text">AfricaNXT Lagos</h3>

              <p
                className="white_text"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                In February, we hosted our first event of the year at the
                AfricaNXT in Lagos sharing exciting insights on the growth of
                embedded finance on the African continent.
              </p>
            </div>
          </div>

          <div className="flex_items items_stretch events_w_text">
            <div className="flex_item_left">
              <img src={Neobanking} alt="Lagos Startup expo" />

              <div className="event_text">
                <h3>Lagos Startup expo</h3>

                <p data-aos="fade-up" data-aos-duration="1000">
                  We connected with industry leaders, entrepreneurs, and tech
                  enthusiasts at the Lagos Startup Expo organised by Techpoint
                  Africa.
                </p>
              </div>
            </div>

            <div className="flex_item_right">
              <img src={LagosTechFest} alt="Lagos Tech Fest" />

              <div className="event_text">
                <h3>Lagos Tech Fest</h3>

                <p data-aos="fade-up" data-aos-duration="1000">
                  We hosted a panel session on “Building a Collaborative
                  Firewall Against Fraud in Nigeria's Fintech Ecosystem at the
                  Lagos Tech Fest.
                </p>
              </div>
            </div>
          </div>

          <div className="events_others">
            {eventsOthers.map((event) => (
              <div className="event" key={event.name}>
                <img src={event.image} alt={event.name} />
              </div>
            ))}
          </div>

          <div className="d_t_w">
            <img src={DigitalTechWeek} alt="Digital Tech Week" />
          </div>
        </div>
      </section>

      <section className="footer">
        <div className="footer_inner desktop_max_width">
          <div className="section_tag" data-aos="zoom-in-down">
            Cheers to a great 2024 🥂
          </div>

          <div className="message">
            <p data-aos="fade-up" data-aos-duration="1000">
              We couldn’t have come this far without the support of our
              investors, partners, and customers. Thank you for being an
              integral part of the Anchor journey
            </p>

            <p data-aos="fade-up" data-aos-duration="1000">
              The best is yet to come! Join us as we unveil a glimpse into our
              exciting plans and the ambitious vision we have for the future.
            </p>

            <Anchor3d className="anchor_3d" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
